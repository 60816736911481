export default function Home() {
  return (
    <main className="relative isolate min-h-screen">
      <img
        alt=""
        src="https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75"
        className="absolute inset-0 -z-10 h-full w-full object-cover object-center"
      />
      <div className="max-w-7xlpx-6 mx-auto py-32 text-center sm:py-40 lg:px-8">
        <div className="mx-auto w-fit rounded-2xl bg-black/10 p-8">
          <p className="text-2xl font-semibold leading-8 text-white">401</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">KDB 제작중</h1>
          <p className="mt-4 text-base text-white/70 sm:mt-6">최대한 빠르게 서비스를 제공하도록 하겠습니다.</p>
          <div className="mt-10 flex justify-center">
            <a href="mailto:neros12@naver.com" className="text-base font-semibold leading-7 text-white">
              Contact
            </a>
          </div>
        </div>
      </div>
    </main>
  );
}
